<template>
  <div>
    <div class="widgetContainer widgetContainer--full-screen widgetContainer--center welcome">
      <div class="welcome__logo set-logo"/>
      <div class="welcome__header">
        <p data-testid="welcome-txt">
          {{ $t('welcome_title') }}
        </p>
        <span data-testid="welcome-desc">{{ welcomText }}</span>
      </div>
      <div v-if="person" class="login_buttons">
        <el-button type="primary" :style="{background: primaryColor}"
                   class="welcome__get-started el-button__brand brand"
                   @click="resume()">
          {{ $t('continue') }}
        </el-button>
        <el-button type="text" @click="logout()">
          {{ $t('profile_logoutn_btn') }}
        </el-button>
      </div>

      <template v-else>
        <ul class="welcome__switch-list" v-if="isTestModeEnabled == true">
          <li
            data-testid="test-mode"
            @click="login(currentMode === 'prod' ? 'prodtest' : mode)"
            class="test-mode">
            <p>
              {{ $t('prod_test') }}
            </p>
            <span class="icon-Arrow-small-right" />
          </li>
          <li
            data-testid="live-mode"
            @click="login('prod')"
            class="live-mode">
            <p>
              {{ $t('prod_live') }}
            </p>
            <span class="icon-Arrow-small-right" />
          </li>
        </ul>
        <el-button v-else type="primary" :style="{background: primaryColor}"
                   class="welcome__get-started el-button__brand brand"
                   @click="login(mode)">
          {{ $t('Restart_get_started_button_title') }}
        </el-button>
      </template>
    </div>
  </div>
</template>
<script>
import { setEnv, getBrandConfig, getBrandKey, getUIConfig } from '@/utils/env';
import { mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import auth0 from '@/plugins/auth0';

export default {
  name: 'Welcome',
  data: () => {
    return {
      clickCount: 0,
      devToolOpenStatus: false,
      isEnvChanged: false,
      mode: 'prodtest',
      person: null,
      envOptions: {
        qa: 'QA',
        development: 'Dev',
        staging: 'Staging',
        prodtest: 'Test mode',
        prod: 'Production'
      }
    };
  },
  created() {
    this.mode = this.getActiveEnv;
    if(!this.isTestModeEnabled) {
      Vue.use(auth0);
      const loader = this.showLoader();
      Vue.getAuth0Instance()
        .then(() => {
          setTimeout(() => {
            const loader = this.showLoader();
            this.checkSession()
              .then(() => {
                this.getPersonFromAuth0()
                  .then((person) => {
                    if(person) {
                      this.person = person
                    }
                  })
              }).finally(() => {
                loader.close();
              })
          }, 0)
        })
        .finally(() => {
          loader.close();
        })
    }
  },
  computed: {
    ...mapGetters(['getActiveEnv']),
    currentMode() {
      return process.env.VUE_APP_ENV;
    },
    isTestModeEnabled() {
      return (getUIConfig('isTestModeEnabled', true));
    },
    primaryColor() {
      return (getBrandConfig('primaryColor', '#141414'));
    },
    welcomText(){
      if(this.person) {
        return this.$t('try_oldSession', { value1: this.person.name })
      }
      const key = getBrandKey();
      return this.$t(key !== 'solid' ? (key+'_welcome_tagline') : 'welcome_desc')
    }
  },
  methods: {
    ...mapActions('user', ['startLogin', 'getPersonFromAuth0', 'logout', 'checkSession']),
    async login(mode) {
      this.mode = mode;
      setEnv(this.mode);
      window.sessionStorage.setItem('selectedEnv', mode);

      setTimeout(() => {
        window.analytics && window.analytics.track('getStarted');
        if(this.isTestModeEnabled) {
          Vue.use(auth0);

          const loader = this.showLoader();

          Vue.getAuth0Instance()
            .then(() => {
              setTimeout(() => {
                const loader = this.showLoader();
                this.checkSession()
                  .then(() => {
                    this.getPersonFromAuth0()
                      .then((person) => {
                        console.log('person', person)
                        if(person) {
                          this.person = person
                        } else {
                          this.startLogin();
                        }
                      }).catch(() => {
                        this.startLogin();
                      })
                  }).catch(() => {
                    this.startLogin();
                  }).finally(() => {
                    loader.close();
                  })
              }, 0)
            })
            .finally(() => {
              loader.close();
            })
        } else {
          this.startLogin();
        }
      }, 0);
    },
    resume() {
      window.sessionStorage.setItem('selectedEnv', this.mode);
      this.startLogin();
    },
  }
};
</script>
<style lang="scss">
.welcome {
  justify-content: space-between;
  padding: 15px;
  background: var(--branding-sidebar);

  #hello-sign-container {
    height: 70vh;
  }

  .welcome__logo {
    height: 40px;
    margin-top: 66px;
    width: auto;
  }

  &__header {
    text-align: center;

    p {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 7px;
    }

    span {
      color: var(--branding);
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 32px;
      display: inline-block;
      padding: 0 25px 32px 25px;
    }
  }

  &__description {
    padding: 15px 0 15px 0;
  }

  &__switch-list {
    margin-top: 10px;
    border-radius: var(--radius);
    padding-bottom: 2px;

    li {
      position: relative;
      padding: 21px 16px 21px 48px;
      background: var(--branding-sidebar);
      border: 1px solid var(--branding-sidebar-border);
      border-radius: var(--radius);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background: var(--branding-sidebar-hover);
      }

      &:first-of-type {
        margin-bottom: 16px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        border-radius: 50%;
        width: 16px;
        height: 16px;
      }

      &.test-mode {
        &:before {
          background-color: var(--orange2);
        }
      }

      &.live-mode {
        &:before {
          background-color: var(--color-green);
        }
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: var(--branding);
      }

      span {
        font-size: 12px;
      }
    }
  }

  login_buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .welcome__get-started {
      flex: 1
    }
    .el-button--text {
      color: #999;
      text-decoration: underline;
      width: 50px;
      padding: 0;
      height: auto !important;
      margin: 0 auto;
    }
  }

}

// Popup env
.popup-env {
  overflow: hidden;

  .wise-popup {
    margin: auto;
    width: 100%;
    max-width: 720px;
    min-height: 70vh;
    max-height: 90vh;
  }
  
  .cancel-btn {
    max-width: 100px;
    align-self: flex-end;
  }
}
</style>
